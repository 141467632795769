<template>
	<div class="QandA">
		<div class="title">
			{{i18n('21')}}
		</div>
		<div class="inner">
			<qaItem v-for="(item,index) in listData" :key="index" :data='item'></qaItem>
		</div>
	</div>
</template>

<script>
	import {
		qaList
	} from "@/api/content.js"
	
	import qaItem from "./components/qaItem.vue"
	export default {
		name: "QandA",
		components: {
			qaItem
		},
		data() {
			return {
				listData: [],
				params: {
					page_num: 1,
					page_size: 200,
					language: 0, //语言 0.中文 1.英文,2越南文
				},
				isNext: true
			}
		},
		methods: {
			qaList() {
				if (this.lang == 'zh') {
					this.params.language = 0
				} else if (this.lang == 'en') {
					this.params.language = 1
				} else if (this.lang == 'vie') {
					this.params.language = 2
				}
				qaList(this.params).then((
					data
				) => {
					if (this.params.page_num >= data.total) {
						this.isNext = false
					}
					this.listData = this.listData.concat(data.data)
				})
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch:{
			lang(){
				this.qaList()
			}
		},
		created() {
			this.qaList()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.inner{
		flex: 1;
		width: 1029px;
		background: #FFFFFF;
		border-radius: 10px;
		overflow-y: scroll;
	}
	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}
	
	.QandA {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
</style>
