<template>
	<div :class="show?'qaItem qaItemAc':'qaItem'">
		<div class="tit" @click="show=!show">
			<div class="left">
				{{data.chinese_title}}
			</div>
			<img src="@/assets/view-list.png" alt="">
		</div>
		<div class="nr" v-html="data.content_chinese" v-if="show">
			
		</div>
	</div>
</template>

<script>
	export default {
		props:['data'],
		name: "qaItem",
		data() {
			return {
				show:false
			}
		},
		methods: {
			
		},
		created() {
			
		},
		mounted() {
	
		}
	};
</script>

<style scoped>
	.qaItem{
		padding: 6px 24px;
	}
	.qaItemAc{
		padding: 6px 24px 0 24px;
	}
	.tit{
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.tit .left{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.tit img{
		width: 16px;
		height: 16px;
		margin-left: 10px;
		display: block;
	}
	.nr{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		background: #F7F8FA;
		padding: 12px 15px 20px 15px;
	}
</style>